/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var AMZ = {
    // All pages
    common: {
      toggleMenu: function() {
        $(".js-toggle-menu").on("click", function(e) {
          e.preventDefault();
          $(".js-nav-menu").toggleClass("vh-100-nl");
          $(".js-logo").toggleClass("P-svg-black");
          $(this).toggleClass("close");
        });
      },

      scrollUp: function() {
        $(".js-scroll-top").on("click", function(e) {
          e.preventDefault();

          $("html, body").animate(
            {
              scrollTop: 0
            },
            1500
          );
        });
      },

      scrollDown: function() {
        $(".js-scroll-down").on("click", function(e) {
          e.preventDefault();

          $("html, body").animate(
            {
              scrollTop: window.innerHeight
            },
            600
          );
        });
      },


      singlePress: {
        updatePreview: function (type, url) {
          const _this = this;
          const sliderPreview = document.querySelector('.slider-preview');
          const sliderItems = Array.from(document.querySelectorAll('.slider-item'));

          if(type == 'image') {
            sliderPreview.innerHTML = "<img src=" + url + "'>";
          }
          else if(type == 'video') {
            sliderPreview.innerHTML = "<div class='video-container'><iframe width='100%' src='https://www.youtube.com/embed/" + url + "' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe></div>";
          }

          sliderItems.forEach(function (sliderItem) {
            sliderItem.addEventListener('click', function (e) {
              e.preventDefault();
              _this.updatePreview(sliderItem.dataset.type, sliderItem.dataset.url);
            })
          });
        },

        init: function () {
          
          const tinySlider = document.querySelector('.tiny-slider');

          if(tinySlider) {
            const firstItem = document.querySelector('.index-0');

            tns({
              container: '.tiny-slider',
              items: 4,
              slideBy: 1,
              autoplay: false,
              loop: true,
              controlsText: ["<svg xmlns='http://www.w3.org/2000/svg' width='15' height='28' viewBox='0 0 15 28'><path fill='#B0AFB0' d='M14.261 27.971L.067 14 14.261.029l.701.713L1.493 14l13.469 13.258z'/></svg>", "<svg xmlns='http://www.w3.org/2000/svg' width='15' height='28' viewBox='0 0 15 28'><path fill='#B0AFB0' d='M.768.029L14.962 14 .768 27.971l-.701-.713L13.536 14 .067.742z'/></svg>"]
            });

            this.updatePreview(firstItem.dataset.type, firstItem.dataset.url);
            
          }
        }
      },

      init: function() {
        this.scrollUp();
        this.scrollDown();
        this.singlePress.init();

        if ($("#barba-wrapper").length) {
          Barba.Pjax.init();
          Barba.Prefetch.init();
          var slug = "";

          Barba.Dispatcher.on("newPageReady", function(currentStatus) {
            var link = currentStatus.url.split("//")[1];
            slug = currentStatus.url.split("/")[3];
            var navigation = document.querySelector(".js-nav-menu");
            var navigationLinkIsActive = navigation.querySelector(
              "[href='http://" + link + "']"
            );

            $(".current_page_item").removeClass("current_page_item");
            $(navigationLinkIsActive)
              .parent()
              .addClass("current_page_item");

            setTimeout(function() {
              if (slug === "") {
                $("body").removeClass("portfolio");
                $("body").removeClass("are-you-amazing");
                $("body").removeClass("press");
                $("body").addClass("home");
              } else if (slug === "portfolio") {
                $("body").removeClass("are-you-amazing");
                $("body").removeClass("home");
                $("body").removeClass("press");
                $("body").addClass("portfolio");
              } else if (slug === "are-you-amazing") {
                $("body").removeClass("portfolio");
                $("body").removeClass("home");
                $("body").removeClass("press");
                $("body").addClass("are-you-amazing");
              } else if (slug === "press") {
              $("body").removeClass("portfolio");
              $("body").removeClass("home");
              $("body").removeClass("are-you-amazing");
              $("body").addClass("press");
            }
               else {
                $("body")
                  .removeClass("are-you-amazing")
                  .removeClass("home")
                  .removeClass("press")
                  .removeClass("portfolio");
              }
            }, 600);

            AMZ.common.scrollUp();
            AMZ.common.scrollDown();
          });

          Barba.Pjax.getTransition = function() {
            return HideShowTransition;
          };

          var HideShowTransition = Barba.BaseTransition.extend({
            start: function() {
              Promise.all([
                this.newContainerLoading,
                this.removeElements()
              ]).then(this.finish.bind(this));
            },

            removeElements: function() {
              var deferred = Barba.Utils.deferred();
              $(".js-content-text").addClass("remove-right");
              $(".js-content-image").addClass("remove-up");
              $(".js-content").addClass("o-0");
              setTimeout(function() {
                deferred.resolve();
              }, 600);
              return deferred.promise;
            },

            finish: function() {
              var _this = this;
              $(".js-content-text").addClass("remove-right");
              $(".js-content-image").addClass("remove-up");
              setTimeout(function() {
                $(".js-content-text").removeClass("remove-right");
                $(".js-content-image").removeClass("remove-up");
                $(".js-content").removeClass("o-0");
                _this.done();
              }, 600);
              if (slug === "portfolio") {
                AMZ.portfolio.init();
              }
              else if (slug == "press") {
                AMZ.press.init();
              }
            }
          });
        }

        this.toggleMenu();

        $(".js-nav-link").on("click", function(e) {
          e.preventDefault();
          if (window.innerWidth <= 1024) {
            $(".js-nav-menu").toggleClass("vh-100-nl");
            $(".js-logo").toggleClass("P-svg-black");
            $(".js-toggle-menu").toggleClass("close");
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    home: {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    portfolio: {
      init: function() {
        $(".js-get-cat").on("click", function(e) {
          var cat = $(this).data("cat");
          $(".js-container").html("");
          var html;

          $.getJSON(
            MyAjax.ajaxurl +
              "?action=getPortfolio&requestNonce=" +
              MyAjax.ajaxnonce +
              "&cat=" +
              cat
          ).done(function(items) {
            items.forEach(function(item, i) {
              var containerClasses = i % 2 === 0 ? "tr-l tc-nl" : "tc-nl";
              var flexClasses =
                i % 2 === 0
                  ? "flex-row-reverse flex-column-reverse-nl "
                  : "flex-column-reverse-nl";
              var alignClass = i % 2 === 0 ? "" : "justify-end";
              var gallery = "";
              var logo = item.logo
                ? '<img src="' + item.logo + '" class="mb-xl" />'
                : "";
              var link =
                item.link === ""
                  ? ""
                  : '<a href="' +
                    item.link +
                    '" target="blank" class="no-barba dib mt-xxl pa-s bd-1 bc-grey f1 uppercase h-bg-grey h-tc-white h-fill-white td-60"">' +
                    '<svg xmlns="http://www.w3.org/2000/svg" class="mr-s svg-grey td-60" height="6" viewBox="0 0 27.18 13.59" width="10"><path d="M22.938 0l-9.347 9.347L4.242 0H0l13.591 13.59L27.181 0z"></path></svg>' +
                    "Visit website" +
                    "</a>";
              if(item.gallery){
                item.gallery.forEach(function(image, j) {
                  var classOne = j > 0 ? "mxw-320 pw-40 " : "";
                  var classTwo =
                    i % 2 === 0 ? (j > 0 ? " ml-m" : "") : j > 0 ? " mr-m" : "";
                  gallery +=
                    '<img class="mb-m ' +
                    classOne +
                    classTwo +
                    '" src="' +
                    image +
                    '">';
                });
              }

              html =
                '<div class="flex ' +
                flexClasses +
                ' pt-xxl">' +
                '<div class="relative pw-100 pw-50-l flex items-center ' +
                alignClass +
                ' ph-l-nl pb-xxxl-nl tc-nl">' +
                '<div class="tc-grey ' +
                ' relative tc-nl pw-100 max-w-600-l pt-l-nl ph-l-l">' +
                logo +
                '<div class="mb-m bullet-list">' +
                '<div class="text-container">' +
                "<p>" +
                item.content +
                "</p>" +
                "</div>" +
                link +
                "</div>" +
                "</div>" +
                "</div>" +
                '<div class="pw-100 pw-50-l max-w-600-l ' +
                containerClasses +
                '">' +
                gallery +
                "</div>";

              $(".js-container").append(html);
            });
            $(".js-portfolio-footer").removeClass("dn");
            var top = $(".js-container").offset().top;
            $("html, body").animate(
              {
                scrollTop: top
              },
              1000
            );
          });
        });
      },
      finalize: function() {}
    },

    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },

    press: {
      init: function() {
        const filters = Array.from(document.querySelectorAll('.js-filter'));
        const loadMore = document.querySelector('.js-load')
        const posts = Array.from(document.querySelectorAll('.js-post'));
        const message = document.querySelector('.js-message');

        if(filters.length > 0) {
          if(posts.length == 0) {
            message.classList.remove('hide');
          }

          filters.forEach(function(filter) {

            filter.addEventListener('click', function(e) {
              e.preventDefault();

              if(!filter.classList.contains('selected')) {
                var activePosts = 0;
                filters.forEach(function (filter) { filter.classList.remove('selected') });
                filter.classList.add('selected');
                loadMore.classList.add('hide');

                posts.forEach(function(post) {
                  const dateContainer = post.querySelector('.js-date');

                  if(filter.dataset.date == 'all') {
                    post.classList.remove('hide');
                    activePosts++;
                  }
                  else if(!dateContainer) {
                    post.classList.add('hide');
                  }
                  else {
                    if (filter.dataset.date == dateContainer.dataset.date) {
                      post.classList.remove('hide');
                      activePosts++;
                    }
                    else {
                      post.classList.add('hide');
                    }
                  }
                });

                if (activePosts == 0) {
                  message.classList.remove('hide');
                }
                else {
                  message.classList.add('hide');
                }
              }
            })
          });

          loadMore.addEventListener('click', function(e) {
            e.preventDefault();

            posts.forEach(function (post) {
              if(post.classList.contains('hide')) {
                post.classList.remove('hide');
                loadMore.classList.add('hide');
              }
            });
          });
        }
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = AMZ;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function(
        i,
        classnm
      ) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, "finalize");
      });

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
